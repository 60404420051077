// do_processes

jQuery('.processes-item-1').hover(
    function(){
        jQuery(this).addClass('hover');
        jQuery('#pro-1').attr('src', 'assets/images/processes-icons/pro-light-1.png');
    },
    function(){
        jQuery(this).removeClass('hover');
        jQuery('#pro-1').attr('src', 'assets/images/processes-icons/pro-1.png');
    }
);


jQuery('.processes-item-2').hover(
    function(){
        jQuery(this).addClass('hover');
        jQuery('#pro-2').attr('src', 'assets/images/processes-icons/pro-light-2.png');
    },
    function(){
        jQuery(this).removeClass('hover');
        jQuery('#pro-2').attr('src', 'assets/images/processes-icons/pro-2.png');
    }
);

jQuery('.processes-item-3').hover(
    function(){
        jQuery(this).addClass('hover');
        jQuery('#pro-3').attr('src', 'assets/images/processes-icons/pro-light-3.png');
    },
    function(){
        jQuery(this).removeClass('hover');
        jQuery('#pro-3').attr('src', 'assets/images/processes-icons/pro-3.png');
    }
);

jQuery('.processes-item-4').hover(
    function(){
        jQuery(this).addClass('hover');
        jQuery('#pro-4').attr('src', 'assets/images/processes-icons/pro-light-4.png');
    },
    function(){
        jQuery(this).removeClass('hover');
        jQuery('#pro-4').attr('src', 'assets/images/processes-icons/pro-4.png');
    }
);

jQuery('.processes-item-5').hover(
    function(){
        jQuery(this).addClass('hover');
        jQuery('#pro-5').attr('src', 'assets/images/processes-icons/pro-light-5.png');
    },
    function(){
        jQuery(this).removeClass('hover');
        jQuery('#pro-5').attr('src', 'assets/images/processes-icons/pro-5.png');
    }
);


//

jQuery('.services-item-1').hover(
    function(){
        jQuery(this).addClass('hover');
        jQuery('#ser-1').attr('src', 'assets/images/services-icons/serv-light-1.png');
    },
    function(){
        jQuery(this).removeClass('hover');
        jQuery('#ser-1').attr('src', 'assets/images/services-icons/serv-1.png');
    }
);


jQuery('.services-item-2').hover(
    function(){
        jQuery(this).addClass('hover');
        jQuery('#ser-2').attr('src', 'assets/images/services-icons/serv-light-2.png');
    },
    function(){
        jQuery(this).removeClass('hover');
        jQuery('#ser-2').attr('src', 'assets/images/services-icons/serv-2.png');
    }
);



jQuery('.services-item-3').hover(
    function(){
        jQuery(this).addClass('hover');
        jQuery('#ser-3').attr('src', 'assets/images/services-icons/serv-light-3.png');
    },
    function(){
        jQuery(this).removeClass('hover');
        jQuery('#ser-3').attr('src', 'assets/images/services-icons/serv-3.png');
    }
);


jQuery('.services-item-4').hover(
    function(){
        jQuery(this).addClass('hover');
        jQuery('#ser-4').attr('src', 'assets/images/services-icons/serv-light-4.png');
    },
    function(){
        jQuery(this).removeClass('hover');
        jQuery('#ser-4').attr('src', 'assets/images/services-icons/serv-4.png');
    }
);


//


jQuery('.need-item-1').hover(
    function(){
        jQuery(this).addClass('hover');
        jQuery('#item-1').attr('src', 'assets/images/need-help-icons/need-light-1.png');
    },
    function(){
        jQuery(this).removeClass('hover');
        jQuery('#item-1').attr('src', 'assets/images/need-help-icons/need-1.png');
    }
);


jQuery('.need-item-2').hover(
    function(){
        jQuery(this).addClass('hover');
        jQuery('#item-2').attr('src', 'assets/images/need-help-icons/need-light-2.png');
    },
    function(){
        jQuery(this).removeClass('hover');
        jQuery('#item-2').attr('src', 'assets/images/need-help-icons/need-2.png');
    }
);


jQuery('.need-item-3').hover(
    function(){
        jQuery(this).addClass('hover');
        jQuery('#item-3').attr('src', 'assets/images/need-help-icons/need-light-3.png');
    },
    function(){
        jQuery(this).removeClass('hover');
        jQuery('#item-3').attr('src', 'assets/images/need-help-icons/need-3.png');
    }
);


